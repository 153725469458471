<template>

<div class="icon" :class="{'is-inline': inline}">

	<i :class="{[clsIcon]: true}"></i>

	<slot></slot>

</div>

</template>

<script>

export default {

	props: ['s', 'inline'],

	data: function() {

		return {
			shortcut: {
				error: 'fa fa-exclamation-triangle',
				close: 'fa fa-times',
				search: 'fas fa-search',
				phone: 'fa fa-phone',
				twitter: 'fa-brands fa-x-twitter',
				facebook: 'fab fa-facebook',
				facebookPlain: 'fab fa-facebook-f',
				youtube: 'fab fa-youtube',
				instagram: 'fab fa-instagram',
				linkedin: 'fab fa-linkedin',
				linkedinPlain: 'fab fa-linkedin-in',
				email: 'fa fa-envelope',
				print: 'fa fa-print',
				chevronRight: 'fa fa-chevron-right',
				chevronDown: 'fa fa-chevron-down',
				chevronUp: 'fa fa-chevron-up',
				info: 'fa fa-info',
				check: 'fa fa-check',
				loading: 'fas fa-spinner fa-spin',
				menu: 'fa fa-bars'
			}
		}

	},

	computed: {

		clsIcon: function() {

			return this.shortcut[this.s]

		}

	}

}

</script>

<style scoped>

.icon.is-inline {
	display: inline-block;
}

</style>
